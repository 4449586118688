module.exports = {
  siteUrl: process.env.SITE_URL,
  siteName: 'Headlinker',
  linkedin: 'https://www.linkedin.com/company/96305860/admin/feed/posts/',
  youtubeChannelUrl: 'https://www.youtube.com/@headlinker',
  slackUrl: 'https://join.slack.com/t/headlinker/shared_invite/zt-2irlnc1xi-8lfO31KvQQMm_HhldzPRVw',
  whatsapp: 'https://chat.whatsapp.com/Idmc78yFoUlB4UkYE1WQ4d',
  whatsappMartin: 'https://wa.me/+33662478230',
  whatsappDorothee: 'https://wa.me/+33632791518',
  linkedinDorothee: 'https://wa.me/+33632791518',
  calendarUrl: 'https://calendly.com/decalan-dorothee/echange-headlinker',
  email: 'hello@headlinker.com',
  emailHello: 'hello@headlinker.com',
  twitterHandle: 'martinratinaud',
  bucketsMapping: {
    [`${process.env.R2_ENDPOINT}/${process.env.R2_BUCKET}`]: process.env.R2_PUBLIC_URL,
  },
  membershipFeePaymentLink: `${process.env.SITE_URL}/join/membership`,
};
