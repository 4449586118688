import { gql, useMutation, useQuery } from 'modules/GraphQL';
import { useAuthModal } from 'modules/auth';
import type { AppProps } from 'next/app';
import React from 'react';
import { type User } from 'modules/User/interfaces';

const GET_SUBSCRIPTION = gql`
  query MySubscription {
    mySubscription {
      item {
        plans {
          id
          status
          validUntil
        }
      }
    }
  }
`;

const SUBSCRIBE = gql`
  # Increments a back-end counter and gets its resulting value
  mutation createSubscription($input: createSubscriptionInput!) {
    createSubscription(input: $input) {
      item {
        id
        url
      }
    }
  }
`;

const DELETE_SUBSCRIPTION = gql`
  # Increments a back-end counter and gets its resulting value
  mutation deleteSubscription($input: deleteSubscriptionInput!) {
    deleteSubscription(input: $input) {
      status
    }
  }
`;
const GET_BILLING_PORTAL = gql`
  mutation GetBillingPortal($input: getBillingPortalInput!) {
    getBillingPortal(input: $input) {
      url
    }
  }
`;

type SubscriptionContextType = {
  subscription: { type: 'pro_monthly' | 'pro_yearly'; endAt: string } | null;
  loading: boolean;
  refresh: () => void;
  createSubscription: (input: { plan: string; coupon?: string; redirectUrl?: string }) => Promise<void>;
  loadingSubscription: boolean;
  deleteSubscription: (input: { subscriptionId: string }) => Promise<void>;
  deletingSubscription: boolean;
  getBillingPortal: (input: { redirectUrl: string }) => Promise<void>;
  loadingBillingPortal: boolean;
  isPlanActive: (planId: string) => boolean;
  plans: User['plans'];
  currentPlan: string | null;
};

export const SubscriptionContext = React.createContext<SubscriptionContextType>({
  subscription: null,
  loading: false,
  refresh: () => {},
  createSubscription: (() => {}) as any,
  loadingSubscription: false,
  deleteSubscription: (() => {}) as any,
  deletingSubscription: false,
  getBillingPortal: (() => {}) as any,
  loadingBillingPortal: false,
  isPlanActive: () => false,
  plans: [],
  currentPlan: null,
});

export default function SubscriptionProvider({ children }: AppProps['pageProps'] & any) {
  const { session } = useAuthModal();
  const { loading, data, refetch } = useQuery(GET_SUBSCRIPTION, { skip: !session });
  const [createSubscriptionMutation, { loading: loadingSubscription }] = useMutation(SUBSCRIBE);
  const [, { loading: deletingSubscription }] = useMutation(DELETE_SUBSCRIPTION);
  const [getBillingPortalMutation, { loading: loadingBillingPortal }] = useMutation(GET_BILLING_PORTAL);

  const subscription = data?.mySubscription?.item;

  const plans = session?.user?.plans || [];
  const isPlanActive = (planId: string) => plans?.some((plan: any) => plan.id === planId && plan.status === 'active');
  const currentPlan = isPlanActive('pro')
    ? 'pro'
    : isPlanActive('teambooster')
    ? 'teambooster'
    : isPlanActive('booster')
    ? 'booster'
    : isPlanActive('starter')
    ? 'starter'
    : null;

  const createSubscription = async ({ plan, coupon, redirectUrl }: { plan: string; coupon?: string; redirectUrl?: string }) => {
    const { data } = await createSubscriptionMutation({
      variables: { input: { plan, coupon, redirectUrl } },
    });

    if (data?.createSubscription?.item) {
      window.location.href = data?.createSubscription?.item?.url;
    }
  };

  const getBillingPortal = async ({ redirectUrl }: { redirectUrl?: string } = {}) => {
    const { data } = await getBillingPortalMutation({ variables: { input: { redirectUrl } } });

    if (data?.getBillingPortal) {
      window.open(data?.getBillingPortal?.url, '_blank');
    }
  };

  const deleteSubscription = async () => {
    await getBillingPortal();
  };

  return (
    <SubscriptionContext.Provider
      value={{
        subscription,
        loading,
        refresh: refetch,
        createSubscription,
        loadingSubscription,
        deleteSubscription,
        deletingSubscription,
        getBillingPortal,
        loadingBillingPortal,
        isPlanActive,
        plans,
        currentPlan,
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
}
