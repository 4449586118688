import React from 'react';
import SEO from 'modules/common/components/SEO';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import Logo from 'modules/common/components/Logo';
import InstallPwaInstructions from '../components/InstallPwaInstructions';
import { LanguageSwitcher } from 'modules/i18n';

const InstallPwaPage = () => {
  const { siteName } = usePublicRuntimeConfig();

  return (
    <>
      <SEO />
      <main className="bg-white dark:bg-slate-900 relative flex h-screen">
        {/* Content */}
        <div className="flex-1 flex flex-col items-center justify-center min-w-[50%]">
          <Logo large shape="square" data-aos="zoom-y-out" data-aos-delay="50" />
          <div className="mx-5 sm:mx-auto sm:w-full md:w-3/4 lg:w-2/3">
            <h1 className="text-3xl text-slate-800 dark:text-slate-100 font-bold mb-6 text-center">{siteName}</h1>
            <InstallPwaInstructions />
          </div>
          <LanguageSwitcher />
        </div>
      </main>
    </>
  );
};

export default InstallPwaPage;
