import useIsMobile from 'modules/common/hooks/useIsMobile';
import { Link } from 'modules/i18n';
import { useNotifier } from 'modules/notification';
import useUser from 'modules/User/hooks/useUser';
import type { AppProps } from 'next/app';
import PullToRefresh from 'pulltorefreshjs';
import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';

import { useTranslation } from 'modules/i18n';
import { User } from 'modules/User/interfaces';
import { MdArrowCircleUp } from 'react-icons/md';
import { UAParser } from 'ua-parser-js';
const parser = new UAParser();

declare global {
  interface Window {
    workbox: any; // Adjust according to the actual Workbox type if available
  }
}

export type PwaContextValue = {
  isInApp: boolean;
  installApp?: () => void;
  subscribed?: boolean;
  loading?: boolean;
  subscribe: (event?: React.MouseEvent<HTMLButtonElement>) => Promise<User>;
  subscription?: PushSubscription | null;
  subscribing: boolean;
  unsubscribe: (event?: React.MouseEvent<HTMLButtonElement>) => Promise<User>;
  registerPush: ReturnType<any>;
  sendPush: (event: React.MouseEvent<HTMLButtonElement>) => Promise<void>;
  userAgent: UAParser.IResult;
} & ReturnType<typeof useIsMobile>;

export const PwaContext = React.createContext<PwaContextValue | undefined>(undefined);

const base64ToUint8Array = (base64: string): Uint8Array => {
  const padding = '='.repeat((4 - (base64.length % 4)) % 4);
  const b64 = (base64 + padding).replace(/-/g, '+').replace(/_/g, '/');

  const rawData = window.atob(b64);
  const outputArray = new Uint8Array(rawData.length);

  for (let i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i);
  }
  return outputArray;
};

export default function PwaProvider({ children }: AppProps['pageProps'] & any) {
  const [subscription, setSubscription] = useState<PushSubscription | null>();
  const [registration, setRegistration] = useState<ServiceWorkerRegistration | null>(null);
  const [loading, toggleLoading] = useState(true);
  const [subscribing, toggleSubscribing] = useState(false);
  const [isInApp, toggleInApp] = useState(false);
  const [installAppPromptEvent, setInstallAppPromptEvent] = useState<Event | null>();
  const { notify } = useNotifier();
  const { t } = useTranslation();
  const mobileInfos = useIsMobile();
  const userAgent = parser.getResult();

  const { user, updateUser, refetchUser } = useUser();

  const serverSubscriptions = React.useMemo(() => user?.notifications?.subscriptions || [], [user]);

  const localSubscriptionKey = subscription?.toJSON()?.keys?.p256dh;
  const subscribed = subscription === undefined ? true : serverSubscriptions.find((s: any) => s.keys.p256dh === localSubscriptionKey);
  // const hasServerSubscription = serverSubscriptions.find((s: any) => s.keys.p256dh === localSubscriptionKey);

  // const testNotification = trpc.push.testNotification.useMutation({});
  const subscribeToPush = async ({ subscription }: any) => {
    notify(
      'success',
      <>
        Bravo, tu peux mettre à jour ces préférences sur{' '}
        <Link href="/user/settings" className="underline">
          ton profil
        </Link>
      </>
    );
    const updatedUser = await refetchUser();
    await updateUser({
      notifications: { ...updatedUser.notifications, subscriptions: [...(updatedUser?.notifications?.subscriptions || []), subscription] },
    });
    toggleSubscribing(false);
  };
  const unsubscribeFromPush = React.useCallback(
    async (key: string) => {
      notify('success', 'Bravo');
      const updatedUser = await refetchUser();
      await updateUser({
        notifications: {
          ...updatedUser.notifications,
          subscriptions: (updatedUser?.notifications?.subscriptions || []).filter((s: any) => s.keys.p256dh !== key),
        },
      });
      toggleSubscribing(false);
    },
    [updateUser, toggleSubscribing, notify, refetchUser]
  );

  const installApp = async () => {
    if (!installAppPromptEvent) {
      return;
    }
    (installAppPromptEvent as any).prompt();
    const { outcome } = await (installAppPromptEvent as any).userChoice;
    if (outcome === 'accepted') {
      setInstallAppPromptEvent(null);
    }
  };

  const registerServiceWorker = async () => {
    const reg = await navigator.serviceWorker.ready;
    setRegistration(reg);
    const sub = await reg.pushManager.getSubscription();

    if (sub && !(sub.expirationTime && Date.now() > sub.expirationTime - 5 * 60 * 1000)) {
      setSubscription(sub);
    } else {
      setSubscription(null);
    }
    toggleLoading(false);
  };

  useEffect(() => {
    if (!(typeof window !== 'undefined' && 'serviceWorker' in navigator && window.workbox !== undefined)) {
      return;
    }
    const inApp =
      window.matchMedia('(display-mode: standalone)').matches || (window.navigator as any).standalone || document.referrer.includes('android-app://');

    toggleInApp(inApp);
    const wb = window.workbox;
    // https://developers.google.com/web/tools/workbox/reference-docs/latest/module-workbox-window.Workbox#events
    // wb.addEventListener('installed', (event: any) => console.log('pwa', event.type));
    // wb.addEventListener('controlling', (event: any) => console.log('pwa', event.type));
    // wb.addEventListener('activated', (event: any) => console.log('pwa', event.type));
    // wb.addEventListener('waiting', (event: any) => console.log('pwa', event.type));
    // wb.addEventListener('message', (event: any) => console.log('pwa', event.type));
    // wb.addEventListener('redundant', (event: any) => console.log('pwa', event.type));
    // wb.addEventListener('externalinstalled', (event: any) => console.log('pwa', event.type));
    // wb.addEventListener('externalactivated', (event: any) => console.log('pwa', event.type));

    registerServiceWorker();

    window.addEventListener('beforeinstallprompt', setInstallAppPromptEvent);
    if (inApp) {
      PullToRefresh.init({
        mainElement: 'body',
        onRefresh() {
          window.location.reload();
        },

        iconArrow: ReactDOMServer.renderToString(<MdArrowCircleUp className="size-8 h-8 w-8 mx-auto" />),
        iconRefreshing: ReactDOMServer.renderToString(<span className="loading loading-ring" />),
        shouldPullToRefresh: () => {
          return !document.querySelector('.modal-overlay') && !window.scrollY;
        },
      });
    }

    if (!(navigator.serviceWorker as any)?.active) {
      wb.register();
    }

    return () => {
      window.removeEventListener('beforeinstallprompt', setInstallAppPromptEvent);
      if (inApp) {
        PullToRefresh.destroyAll();
      }
    };
  }, []);

  const subscribe = async (event?: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    if (subscribed && serverSubscriptions.find((s: any) => s.keys.p256dh === localSubscriptionKey)) {
      return user;
    }
    toggleSubscribing(true);
    event?.preventDefault();
    if (Notification.permission !== 'granted') {
      const permission = await Notification.requestPermission();
      if (permission !== 'granted') {
        alert(
          "La permission n'a pas été accordée, ouvre chrome://settings/content dans ton navigateur et autorise les notifications pour Headlinker"
        );
        console.error('Permission not granted: ', permission);
        toggleSubscribing(false);
        return user;
      }
    }
    try {
      const sub = await registration?.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: base64ToUint8Array(process.env.NEXT_PUBLIC_WEB_PUSH_PUBLIC_KEY as string),
      });
      if (!sub) {
        notify('error', t('pwa.common:subscribeError'));
        await unsubscribe(event);
        return;
      }
      setSubscription(sub);

      if (user) {
        await subscribeToPush({ subscription: sub.toJSON() as any });
      } else {
        notify('error', 'No user is connected');
        await unsubscribe(event);
      }
    } catch (e: any) {
      console.log(e.toString());

      if (e.toString().includes('permission denied')) {
        notify('error', t('pwa.common:enableNotifications'));
      } else {
        notify('error', e.toString());
      }
      await unsubscribe(event);
    }
    const updatedUser = await refetchUser();
    return updatedUser;
  };

  const unsubscribe = React.useCallback(
    async (event?: React.MouseEvent<HTMLButtonElement>) => {
      toggleSubscribing(true);
      event?.preventDefault();
      await subscription?.unsubscribe();
      if (user) {
        await unsubscribeFromPush(localSubscriptionKey as string);
      } else {
        toggleSubscribing(false);
      }
      setSubscription(null);
      const updatedUser = await refetchUser();
      return updatedUser;
    },
    [subscription, user, unsubscribeFromPush, localSubscriptionKey, refetchUser]
  );

  const sendPush = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    event.preventDefault();
    if (subscription == null) {
      alert(t('pwa.common:pushNotSubscribed'));
      return;
    }
    // testNotification.mutate({ subscription: subscription.toJSON() });
  };

  // React.useEffect(() => {
  //   if (!loadingServerSubscription && !hasServerSubscription && subscribed && !subscribing && !loading) {
  //     unsubscribe();
  //   }
  // }, [loadingServerSubscription, subscribed, hasServerSubscription, subscribing, unsubscribe, loading]);

  return (
    <PwaContext.Provider
      value={{
        isInApp,
        installApp: installAppPromptEvent ? installApp : undefined,
        subscribed,
        subscribe,
        loading,
        subscription,
        subscribing,
        unsubscribe,
        registerPush: subscribeToPush,
        sendPush,
        ...mobileInfos,
        userAgent,
      }}
    >
      {children}
    </PwaContext.Provider>
  );
}
