import React from 'react';
import classNames from 'classnames';
import usePublicRuntimeConfig from '../hooks/usePublicRuntimeConfig';
import Image from 'next/image';

type LogoProps = {
  shape?: 'square' | 'wide';
  large?: boolean;
} & React.HTMLAttributes<HTMLDivElement>;

const Logo: React.FC<LogoProps> = ({ className, shape = 'wide', large = false, ...props }) => {
  const { siteName } = usePublicRuntimeConfig();

  let width = 180;
  let height = 56;

  if (shape === 'square') {
    height = large ? 128 : 36;
    width = large ? 128 : 36;
  }
  if (shape === 'wide') {
    height = large ? 128 : 36;
    width = large ? 128 : 36;
  }

  return (
    <figure className={classNames('logo', `logo-${shape}`, className)} data-size={large ? 'lg' : ''} {...props}>
      <Image src={`/logos/logo-square.png`} height={height} width={width} alt={`Logo ${siteName}`} />
      {shape === 'wide' && siteName}
    </figure>
  );
};

export default Logo;
