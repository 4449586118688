import classNames from 'classnames';
import React from 'react';
import usePwa from '../hooks/usePwa';

type PushSubscriptionFormProps = React.HTMLAttributes<HTMLDivElement> & {
  // TODO
};

const PushSubscriptionForm: React.FC<PushSubscriptionFormProps> = ({ className, ...props }) => {
  const { subscribed, subscribe, unsubscribe, subscribing } = usePwa();

  return (
    <div className={classNames('', className)} {...props}>
      {subscribed ? (
        <form>
          <p>Vous êtes abonné aux notifications</p>
          <button type="button" onClick={unsubscribe} className="btn btn-error btn-outline mt-3">
            Ne plus recevoir les notifications
          </button>
        </form>
      ) : (
        <form>
          <p>Vous n’êtes actuellement pas abonné aux notifications</p>
          <button type="button" onClick={subscribe} className="btn btn-primary mt-3">
            {subscribing && <span className="loading"></span>}
            Recevoir les notifications
          </button>
        </form>
      )}
    </div>
  );
};

export default PushSubscriptionForm;
