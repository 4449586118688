import countries from './data/countries.json';

export function getFlagEmoji(countryCode: string) {
  const codePoints = countryCode
    .replace('en', 'gb')
    .toUpperCase()
    .split('')
    .map((char) => 127397 + (char as any).charCodeAt());
  return String.fromCodePoint(...codePoints);
}

export function getCountryName(countryCode: string, locale: 'fr' | 'en' = 'fr') {
  const country = countries.find((country) => country._id === countryCode);
  return country?._i18n[locale].name;
}
