'use client';
import React from 'react';
import { useWindowSize } from '@react-hookz/web';

const useIsMobile = () => {
  const { width: windowWidth } = useWindowSize();
  // This is used to prevent Hydration error on first load
  const [width, setWidth] = React.useState(Infinity);

  React.useEffect(() => {
    setWidth(windowWidth);
  }, [windowWidth]);

  return {
    isMobile: width < 640,
    isTablet: width < 768,
    width,
  };
};

export default useIsMobile;
