'use client';

import { SessionProvider, useSession } from 'next-auth/react';
import type { AppProps } from 'next/app';
import { useRouter } from 'next/router';
import React from 'react';
import { useCookie, useToggle } from 'react-use';
import AuthModal from '../components/AuthModal';
import SubscribeModal from '../components/SubscribeModal';
import type { Session } from '../interfaces';

import { trackAuthCloseModal, trackAuthOpenModal } from 'modules/Analytics';
import useNotifier from 'modules/common/hooks/useNotifier';
import useUrl from 'modules/common/hooks/useUrl';
import useTranslation from 'next-translate/useTranslation';
import { useLocalStorage } from 'react-use';

export const AuthContext = React.createContext({
  modalOpened: false,
  toggleModal: () => {},
  updateSession: (() => {}) as (data?: any) => Promise<Session | null>,
  session: null as Session | null,
  notifyError: (_errorCode: string) => {},
});

const handledErrors = [
  'Signin',
  'OAuthSignin',
  'OAuthCallback',
  'OAuthCreateAccount',
  'EmailCreateAccount',
  'Callback',
  'OAuthAccountNotLinked',
  'EmailSignin',
  'CredentialsSignin',
  'NotConnected',
];

// we need to extract this in order to have access to the session through useSession
const ContextProviderWithSession = ({ value, children }: any) => {
  const { data: session, update: updateSession } = useSession();
  const { queryParams, removeQueryParam, push } = useUrl();
  const { pathname } = useRouter();
  const { notify } = useNotifier();
  const { t } = useTranslation();
  const [redirectAfterLogin, setRedirectAfterLogin, removeRedirectAfterLogin] = useCookie('app-redirect');

  const { error, callbackUrl, redirect } = queryParams;

  const notifyError = (errorCode: string) => {
    if (errorCode && handledErrors.includes(errorCode)) {
      const translatedKey = `auth:error.${errorCode}`;
      const translatedText = t(translatedKey);

      const message =
        translatedKey === translatedText ? (
          errorCode
        ) : (
          <>
            {translatedText} <small className="text-gray-500">{errorCode}</small>
          </>
        );

      notify('error', message, { toastId: errorCode });
    }
  };
  // Display error in notifier and remove it
  React.useEffect(() => {
    if (error) {
      notifyError(error);
      removeQueryParam('error', undefined, { shallow: true });
    }
  }, [error, notify, removeQueryParam, t]);

  React.useEffect(() => {
    if (callbackUrl) {
      push(callbackUrl);
    }
  }, [push, callbackUrl]);

  React.useEffect(() => {
    if (redirect) {
      setRedirectAfterLogin(redirect);
      removeQueryParam('redirect', undefined, { shallow: true });
    }
  }, [redirect, removeQueryParam, setRedirectAfterLogin]);

  React.useEffect(() => {
    if (session) {
      if (redirectAfterLogin) {
        removeRedirectAfterLogin();
        push(redirectAfterLogin);
      }
    }
  }, [pathname, session, redirectAfterLogin, removeRedirectAfterLogin, push]);

  return (
    <AuthContext.Provider
      value={{
        ...value,
        session,
        updateSession,
        notifyError,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default function AuthProvider({ pageProps: { session }, children }: AppProps['pageProps'] & any) {
  const [modalOpened, toggleModal] = useToggle(false);
  const [subscribeModalOpened, toggleSubscribeModal] = useToggle(false);
  const [subscribeModalShownOnce, toggleSubscribeModalShownOnce] = useLocalStorage('sc-subscribe-modal-shown', false);

  const toggleModalWithAnalytics = (val: boolean = true) => {
    if (val) {
      trackAuthOpenModal();
    } else {
      trackAuthCloseModal();
    }
    toggleModal(val);
  };

  React.useEffect(() => {
    if (!subscribeModalShownOnce && session?.user?.plans?.length > 0) {
      setTimeout(() => {
        toggleSubscribeModal(true);
        toggleSubscribeModalShownOnce(true);
      }, 60 * 4 * 1000);
    }
  }, [subscribeModalShownOnce, toggleModal, toggleSubscribeModalShownOnce, toggleSubscribeModal, session?.user?.plans?.length]);

  return (
    <SessionProvider session={session}>
      <AuthModal open={modalOpened} onClose={() => toggleModalWithAnalytics(false)} />
      <SubscribeModal
        open={subscribeModalOpened}
        onClose={() => toggleSubscribeModal(false)}
        onAuth={() => {
          toggleSubscribeModal(false);
          toggleModal(true);
        }}
      />
      <ContextProviderWithSession value={{ modalOpened, toggleModal: toggleModalWithAnalytics }}>{children}</ContextProviderWithSession>
    </SessionProvider>
  );
}
