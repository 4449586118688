import React from 'react';
import classNames from 'classnames';
import usePwa from '../hooks/usePwa';
import { useTranslation } from 'modules/i18n'; // Make sure to import useTranslation

type InstallPwaInstructionsProps = React.HTMLAttributes<HTMLDivElement> & {
  // TODO
};

const InstallPwaInstructions: React.FC<InstallPwaInstructionsProps> = ({ className, ...props }) => {
  const { subscribing, isInApp, installApp, isMobile, isTablet, userAgent } = usePwa();
  const { t } = useTranslation(); // Initialize useTranslation hook

  const browserName = userAgent.browser.name;

  if (isInApp) {
    return null;
  }

  if (!isMobile && !isTablet) {
    return null;
  }

  const renderInstructions = () => {
    switch (browserName) {
      case 'Mobile Chrome':
        return <p>{userAgent.device.vendor === 'Apple' ? t('pwa.common:install.safari') : t('pwa.common:install.chrome')}</p>;
      case 'Mobile Edge':
        return <p>{t('pwa.common:install.edge')}</p>;
      case 'Mobile Firefox':
        return <p>{t('pwa.common:install.firefox')}</p>;
      case 'Mobile Safari':
        return <p>{t('pwa.common:install.safari')}</p>;
      default:
        return <p>{t('pwa.common:install.default')}</p>;
    }
  };

  return (
    <div className={classNames('text-center', className)} {...props}>
      {t('pwa.common:install.intro')}
      <div className="text-center p-5">
        {installApp && (
          <button className="btn mb-24" onClick={installApp}>
            {subscribing ? <span className="loading"></span> : t('pwa.common:install.cta')}
          </button>
        )}

        {renderInstructions()}
      </div>
    </div>
  );
};

export default InstallPwaInstructions;
