import classNames from 'classnames';
import CopyButton from 'modules/common/components/CopyButton';
import EmptyState from 'modules/common/components/EmptyState';
import usePublicRuntimeConfig from 'modules/common/hooks/usePublicRuntimeConfig';
import React from 'react';
import { MdLockOutline } from 'react-icons/md';
import useUser from 'modules/User/hooks/useUser';
import { calendarUrl } from 'modules/App/config';
import { Button } from '@/lib/components/ui/button';
import { Link } from 'modules/i18n';

type AuthorizationScreenProps = {
  type: string;
} & React.HTMLAttributes<HTMLDivElement>;

const AuthorizationScreen: React.FC<AuthorizationScreenProps> = ({ children, type, className, ...props }) => {
  const { emailHello, whatsappDorothee, whatsappMartin, youtubeChannelUrl } = usePublicRuntimeConfig();
  const { hasPermission } = useUser();

  let title = "Tu n'es pas autorisé";
  let subtitle = (
    <>
      Tu n&apos;as pas le droit d&apos;effectuer cette action.
      <br />
      <br />
      contacte nous sur WhatsApp pour toute question
    </>
  );

  if (type === 'member') {
    if (hasPermission('aspiring')) {
      title = 'Bravo à toi d’avoir été sélectionné pour faire partie de notre communauté !';
      subtitle = (
        <>
          <div className="text-left">
            <br />
            <br />
            <p className="pb-2">Nous n’avons pas encore reçu ton règlement, donc il ne te reste plus qu’une étape à franchir :</p>
            <div className="flex justify-center my-5">
              <Button variant="accent" className="btn btn-primary" href="/user/paywall">
                Je m&apos;abonne
              </Button>
            </div>
            <p className="pb-2">Si tu es encore indécis, tu peux :</p>
            <ul>
              <li>
                regarder comment un{' '}
                <a
                  className="link link-primary font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.linkedin.com/posts/dorothee-de-calan_aujourdhui-60-de-nos-nouveaux-utilisateurs-activity-7233765683233202176-DCg9/?utm_source=share&utm_medium=member_desktop`}
                >
                  process parfait
                </a>{' '}
                se passe sur Headlinker
              </li>
              <li>
                Prendre un rendez-vous avec{' '}
                <a className="link link-primary font-bold" href={calendarUrl} target="_blank" rel="noopener noreferrer">
                  Dorothée
                </a>
              </li>
            </ul>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <div className="border border-info border-dashed text-info p-2">
            ⚠️ Afin d&apos;être sûr de recevoir cet email et qu&apos;il ne finisse pas dans tes SPAMS, <strong>veille à ajouter l&apos;email</strong>{' '}
            suivant à ta liste de contacts
            <br />
            <br />
            <strong className="block">
              <CopyButton value={emailHello}>{emailHello}</CopyButton>
            </strong>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <a className="link" href={whatsappDorothee} target="_blank" rel="noopener noreferrer">
            Dorothée
          </a>{' '}
          et{' '}
          <a className="link" href={whatsappMartin} target="_blank" rel="noopener noreferrer">
            Martin
          </a>
          <br />
          <br />
          Et si tu es pressé
        </>
      );
    } else {
      title = "Ton profil n'est pas encore validé";
      subtitle = (
        <>
          <div className="text-left">
            <br />
            Nous sélectionnons nos membres pour garantir la qualité des échanges et l’engagement de chacun. Cette revue de ton profil nous permet de
            mieux te connaître et de te proposer la meilleure expérience possible.
            <br />
            <br />
            <p className="pb-2">En attendant, tu peux :</p>
            <ul>
              <li>
                <Link href="/user/me" className="link link-primary font-bold">
                  Remplir ton profil
                </Link>{' '}
                (<strong>nécessaire si tu souhaites qu’on revoit ton profil</strong>)
              </li>
              <li>
                passer notre test et découvrir{' '}
                <a className="link link-primary font-bold" target="_blank" rel="noopener noreferrer" href={`/test-recruteur-independant`}>
                  quel type de recruteur
                </a>{' '}
                tu es
              </li>
              <li>
                regarder comment un{' '}
                <a
                  className="link link-primary font-bold"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={`https://www.linkedin.com/posts/dorothee-de-calan_aujourdhui-60-de-nos-nouveaux-utilisateurs-activity-7233765683233202176-DCg9/?utm_source=share&utm_medium=member_desktop`}
                >
                  process parfait
                </a>{' '}
                se passe sur Headlinker :{' '}
              </li>
              <li>
                regarder les vidéos explicatives sur notre{' '}
                <a className="link link-primary font-bold" target="_blank" rel="noopener noreferrer" href={youtubeChannelUrl}>
                  chaîne YouTube
                </a>
              </li>
            </ul>
          </div>
          <br />
          <br />
          <div className="text-left">
            Une fois ton profil sélectionné (c’est un processus manuel, donc sois patient), <strong>tu seras informé par email</strong> et tu auras la
            possibilité de prendre rendez-vous avec Dorothée pour discuter de ton profil et de tes besoins.
          </div>
          <br />
          <br />
          <hr />
          <br />
          <div className="border border-info border-dashed text-info p-2">
            ⚠️ Afin d&apos;être sûr de recevoir cet email et qu&apos;il ne finisse pas dans tes SPAMS, <strong>veille à ajouter l&apos;email</strong>{' '}
            suivant à ta liste de contacts
            <br />
            <br />
            <strong className="block">
              <CopyButton value={emailHello}>{emailHello}</CopyButton>
            </strong>
          </div>
          <br />
          <br />
          <hr />
          <br />
          <a className="link" href={whatsappDorothee} target="_blank" rel="noopener noreferrer">
            Dorothée
          </a>{' '}
          et{' '}
          <a className="link" href={whatsappMartin} target="_blank" rel="noopener noreferrer">
            Martin
          </a>
        </>
      );
    }
  }

  return (
    <div className={classNames('p-5 sm:p-24 border border-gray-100 shadow-sm', className)} {...props}>
      <EmptyState title={title} subtitle={subtitle} icon={MdLockOutline} />
    </div>
  );
};

export default AuthorizationScreen;
