'use client';
import useUrl from 'modules/common/hooks/useUrl';
import { useTheme } from 'modules/design';
import React from 'react';
import { ToastContainer, ToastContent, ToastOptions, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const defaultOptions: Partial<ToastOptions> = {
  position: 'top-center',
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
};

export const notify = (variant: ToastOptions['type'] = 'success', message: ToastContent, options: ToastOptions = {}) => {
  if (variant === 'error' && !options.autoClose) {
    options.autoClose = 8000;
  }

  return toast(message, { ...defaultOptions, type: variant, ...options });
};
const useNotifier = () => {
  const notify = (variant: ToastOptions['type'] = 'success', message: ToastContent, options: ToastOptions = {}) => {
    if (variant === 'error' && !options.autoClose) {
      options.autoClose = 8000;
    }

    return toast(message, { ...defaultOptions, theme, type: variant, ...options });
  };
  const [theme] = useTheme();

  return { notify };
};

export const NotifierContainer = (props: any) => {
  const { queryParams, removeQueryParam } = useUrl();
  const [theme] = useTheme();

  React.useEffect(() => {
    if (queryParams.notify) {
      const [variant, message] = queryParams.notify.split(':');

      toast(message, { ...defaultOptions, theme, type: variant, toastId: message });
      removeQueryParam('notify', undefined, { shallow: true });
    }
  }, [queryParams.notify, theme, removeQueryParam]);

  return <ToastContainer {...props} />;
};
export default useNotifier;
