import { cva, type VariantProps } from 'class-variance-authority';
import classNames from 'classnames';
import { cn } from 'lib/utils';
import { useRouter } from 'next/navigation';
import React from 'react';

export const buttonVariants = cva(
  'inline-flex gap-2 items-center justify-center whitespace-nowrap rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        default: 'bg-primary text-white shadow hover:bg-primary/90',
        destructive: 'bg-destructive text-white shadow-sm hover:bg-destructive/90 border',
        primary: 'bg-primary text-white shadow-sm hover:bg-primary/90 border',
        outline: 'border border-input bg-background shadow-sm hover:bg-accent hover:text-accent-foreground',
        'outline-secondary': 'border border-secondary text-secondary bg-background shadow-sm hover:bg-secondary hover:text-white',
        secondary: 'bg-secondary text-white shadow-sm hover:bg-secondary/80',
        accent: 'bg-accent text-accent-foreground shadow-sm hover:bg-accent/80',
        ghost: 'hover:bg-accent hover:text-accent-foreground',
        link: 'underline-offset-4 underline rounded-none',
        'link-destructive': 'text-destructive underline underline-offset-4 rounded-none',
        'outline-destructive': 'border border-destructive text-destructive !bg-destructive shadow-sm hover:!bg-destructive hover:text-white',
      },
      size: {
        default: 'h-9 px-4 py-2',
        xs: 'h-5 rounded-md px-1 text-xs',
        sm: 'h-8 rounded-md px-3 text-xs',
        lg: 'h-10 rounded-md px-8 text-base',
        xl: 'h-16 rounded-md px-10 text-lg',
        icon: 'h-9 w-9',
      },
    },
    defaultVariants: {
      variant: 'default',
      size: 'default',
    },
  }
);

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement>, VariantProps<typeof buttonVariants> {
  loading?: boolean;
  stopPropagation?: boolean;
  badge?: boolean | string;
  error?: boolean;
  href?: string;
  badgeClassName?: string;
}

export const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      type = 'button',
      variant,
      size,
      children,
      onClick: onExternalClick,
      loading,
      badge,
      error,
      badgeClassName,
      href,
      stopPropagation = true,
      ...props
    },
    ref
  ) => {
    const router = useRouter();
    const onClick: React.MouseEventHandler<HTMLButtonElement> = (e) => {
      if (href) {
        if (href.startsWith('http')) {
          window.open(href, '_blank');
        } else {
          router.push(href);
        }
      }

      if (stopPropagation) {
        e.preventDefault();
        e.stopPropagation();
      }

      if (props.disabled) {
        return;
      }

      if (!onExternalClick) {
        return;
      }
      onExternalClick?.(e);
    };

    return (
      <button
        onClick={onClick}
        type={type}
        className={classNames(
          cn(buttonVariants({ variant, size, className })),
          {
            relative: !!badge,
            '!shadow-md !shadow-red-800/90': error,
          },
          className
        )}
        {...props}
        ref={ref}
      >
        {badge && (
          <div
            className={classNames(
              'badge badge-sm absolute right-[-5px] top-[-5px]',
              {
                'badge-accent animate-[ping_1s_ease-in-out_infinite]': !badgeClassName,
              },
              badgeClassName
            )}
          >
            {typeof badge === 'string' ? badge : ''}
          </div>
        )}
        {loading && <span className="loading loading-xs"></span>}
        {children}
      </button>
    );
  }
);

Button.displayName = 'Button';
